// extracted by mini-css-extract-plugin
export var contactLink = "contactsBlock-module--contactLink--eac2c";
export var contactLinkImg = "contactsBlock-module--contactLinkImg--b23ed";
export var contactText = "contactsBlock-module--contactText--29990";
export var contactsTab = "contactsBlock-module--contactsTab--c0d3b";
export var container = "contactsBlock-module--container--df01a";
export var desc = "contactsBlock-module--desc--ad113";
export var headerSection = "contactsBlock-module--headerSection--e3888";
export var locationText = "contactsBlock-module--locationText--3a515";
export var map = "contactsBlock-module--map--1f45d";
export var mapSection = "contactsBlock-module--mapSection--eaf1a";
export var socialLinks = "contactsBlock-module--socialLinks--1ad45";
export var title = "contactsBlock-module--title--c2b86";