import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { formatCleanPhone } from "../../helpers/format"
import { AddressIcon, EmailIcon, PhoneIcon } from "../../assets/images"

import * as styles from "./contactsBlock.module.css"

const ContactLink = ({ href, icon, text }) => (
  <a href={href} className={styles.contactLink}>
    <span className={styles.contactLinkImg}>
      <img src={icon} alt="" />
    </span>
    <span className={styles.contactText}>{text}</span>
  </a>
)

const PhoneNumber = ({ href, icon, text }) => (
  <a href={href} className={styles.contactLink}>
    <span className={styles.contactLinkImg}>
      <img src={icon} alt="" />
    </span>
    <span className={styles.locationText}>{text}</span>
  </a>
)

const ContactsBlock = () => {
  const { t } = useTranslation()
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          allSettings {
            themeSettingsVkLink
            themeSettingsPhone
            themeSettingsMapLink
            themeSettingsInstagramLink
            themeSettingsFacebookLink
            themeSettingsTelegramLink
            themeSettingsYoutubeLink
            themeSettingsEmail
            themeSettingsEmailSales
            themeSettingsAddress
          }
        }
      }
    `
  )

  const {
    themeSettingsPhone: phone,
    themeSettingsEmail: email,
    themeSettingsEmailSales: emailSales,
    themeSettingsAddress: address,
    themeSettingsMapLink: mapLink,
  } = wp.allSettings

  return (
    <div className={"containerAboutCompany"}>
      <div className={styles.headerSection}>
        <p className={styles.title}>{t("КОНТАКТЫ")}</p>
        <p className={styles.desc}>{t("Наш офис в Москве")}</p>
      </div>
      <div className={styles.container}>
        <div className={styles.contactsTab}>
          {phone && (
            <PhoneNumber
              href={`tel:${formatCleanPhone(phone)}`}
              icon={PhoneIcon}
              text={phone}
            />
          )}

          <div className={styles.socialLinks}>
            {email && (
              <ContactLink
                href={`mailto:${email}`}
                icon={EmailIcon}
                text={email}
              />
            )}
            {emailSales && (
              <ContactLink
                href={`mailto:${emailSales}`}
                icon={EmailIcon}
                text={emailSales}
              />
            )}
          </div>

          {address && (
            <span className={styles.contactLink}>
              <span className={styles.contactLinkImg}>
                <img src={AddressIcon} alt="" />
              </span>
              <span className={styles.locationText}>{address}</span>
            </span>
          )}
        </div>
      </div>

      <div className={styles.mapSection}>
        <iframe
          title="Map"
          className={styles.map}
          src={mapLink}
          width="100%"
          height="100%"
          frameBorder="0"
        />
      </div>
    </div>
  )
}

export default ContactsBlock
